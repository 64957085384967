import { useContext } from 'react';
import { SimulationContext } from '../../contexts/SimulationContext';
import TransactionDivider from '../Atoms/TransactionDivider';
import TransactionRowContainer from '../Containers/TransactionRowContainer';
import SkeletonBanner from './SkeletonBanner';

const GlobalLoader = () => {
  const { eipGlobalInformation } = useContext(SimulationContext);
  const { isFireWalletSimulation } = eipGlobalInformation;
  return (
    <div className="bg-[#FAF7FF] h-screen">
      <SkeletonBanner />
      <div className="flex flex-col items-center px-8 py-6">
        <div className="w-[332px] flex flex-col items-start justify-start">
          <div className="w-[316px] h-[21px] bg-gray-300 rounded-lg animate-tailwindPulse mb-2" />
          <div className="w-[131px] h-[21px] bg-gray-300 rounded-lg animate-tailwindPulse" />
        </div>
        <div className="mt-6 mb-2">
          <TransactionRowContainer loading={false}>
            <div className="flex flex-row justify-between">
              <div>
                <div className="flex items-center mb-2">
                  <div className="h-[32px] w-[32px] bg-gray-100 mr-2 rounded-full animate-tailwindPulse" />
                  <div className="w-[69px] h-[24px] bg-gray-100 rounded-lg animate-tailwindPulse" />
                </div>
                <div className="w-[108px] h-[17px] bg-gray-100 rounded-full animate-tailwindPulse" />
              </div>
              <div>
                <div className="w-[125px] h-[56px] bg-gray-100 rounded-lg animate-tailwindPulse" />
              </div>
            </div>
          </TransactionRowContainer>
        </div>
        <TransactionDivider />
        <div className="w-[332px] flex flex-col items-start justify-start mb-4 ml-3">
          <div className="w-[131px] h-[21px] bg-gray-300 rounded-lg animate-tailwindPulse" />
        </div>
        <TransactionRowContainer loading={false}>
          <div className="flex flex-row justify-between items-center h-full">
            <div>
              <div className="flex items-center mb-2">
                <div className="h-[32px] w-[32px] bg-gray-100 mr-2 rounded-full animate-tailwindPulse" />
                <div className="w-[106px] h-[24px] bg-gray-100 rounded-lg animate-tailwindPulse" />
              </div>
            </div>
            <div>
              <div className="w-[25px] h-[25px] bg-gray-100 rounded-full animate-tailwindPulse mr-3" />
            </div>
          </div>
        </TransactionRowContainer>
        {!isFireWalletSimulation && (
          <div className="w-[332px] flex flex-col items-start justify-start">
            <div className="w-[131px] h-[21px] my-4 bg-gray-300 rounded-lg animate-tailwindPulse" />
            <div className="flex items-center mb-2">
              <div className="h-[16px] w-[16px] bg-gray-300 mr-1 rounded-full animate-tailwindPulse" />
              <div className="w-[67px] h-[16px] bg-gray-300 rounded-lg animate-tailwindPulse" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GlobalLoader;
